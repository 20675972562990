import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(
          relativePath: {
            eq: "images/stripes/Artistic_Mountain_Range_Short.jpg"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Metadata
        title="Cookie Policy"
        description="Hope Labs values your privacy and our cookie policy is transparent and easy to understand."
      />
      <section role="main">
        <Row className={"no-margin"}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Artistic mountain range"
          />
        </Row>
        <Row className={"presentation"}>
          <Col className={"mb-2em"}>
            <h1 className={"mainTitle mt-2em"}>Hope Labs Cookie Policy</h1>
            <p className={"pureText"}>
              Hope Labs ('we' or 'us') understand that your privacy is important
              to you and are committed to being transparent about the
              technologies employed. This Cookie Policy explains how and why
              cookies, web beacons, pixels, clear gifs, and other similar
              technologies (collectively “Cookies and Other Tracking
              Technologies”) may be stored on and accessed from your device when
              you use or visit any website or app that posts a link to this
              Policy. This Cookie Policy should be read together with our{" "}
              <Link to={"/privacy"}>Privacy Policy</Link> and our{" "}
              <Link to={"/terms-of-use"}>Terms of use</Link>.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              What are Cookies and Other Tracking Technologies?
            </h4>
            <p className={"pureText"}>
              A cookie is a small text file that can be stored on and accessed
              from your device when you visit our Site, to the extent you agree.
              The other tracking technologies work similarly to cookies and
              place small data files on your devices or monitor your website
              activity to enable us to collect information about how you use our
              Site. This allows our Site to recognize your device from those of
              other users of the Site. The information provided below about
              cookies also applies to these other tracking technologies. You can
              find more information at{" "}
              <a
                href="https://www.allaboutcookies.org"
                target="_blank"
                rel="noreferrer"
              >
                www.allaboutcookies.org
              </a>{" "}
              and{" "}
              <a
                href="www.youronlinechoices.eu"
                target="_blank"
                rel="noreferrer"
              >
                www.youronlinechoices.eu
              </a>
              .
            </p>

            <h4 className={"minorTitle mt-2em"}>
              How does our Site use Cookies and Other Tracking Technologies?
            </h4>
            <p className={"pureText"}>
              Hope Labs uses Cookies and Other Tracking Technologies to identify
              you and your interests, to remember your preferences, and to track
              your use of our Site. We also use Cookies and Other Tracking
              Technologies to control access to certain content on our Site,
              protect the Site, and to process any requests that you make of us.
            </p>
            <p className={"pureText"}>
              To administer our Site and for research purposes, Hope Labs also
              has contracted with third party service providers to track and
              analyze statistical usage and volume information from our Site
              users. These third party service providers use persistent Cookies
              to help us to improve the user experience, manage our Site
              content, and analyze how users navigate and utilize the Site.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              First and Third Party Cookies
            </h4>
            <p className={"pureText"}>
              “First party Cookies” are cookies that belong to McKinsey and that
              McKinsey places on your device. “Third party Cookies” are cookies
              that another party places on your device through our Site. Hope
              Labs may contract with third party service providers to send
              e-mails to users who have provided us with their contact
              information. To help measure and improve the effectiveness of our
              e-mail communications, and/or to determine whether messages have
              been opened and links clicked on, the third party service
              providers may place Cookies on the devices of these users.
            </p>
            <p className={"pureText"}>
              For more information on how these companies collect and use
              information on our behalf, please refer to their privacy policies
              as indicated below.
            </p>
            <p className={"pureText"}>We use the following types of cookies:</p>
            <p className={"pureText"}>
              <span className={"minorTitle"}>Persistent Cookies.</span> We use
              persistent Cookies to improve your experience of using the Sites.
              This includes recording your acceptance of our Cookie Policy to
              remove the cookie message which first appears when you use the
              Sites.
            </p>
            <p className={"pureText"}>
              <span className={"minorTitle"}>Session Cookies.</span> Session
              Cookies are temporary and deleted from your machine when your web
              browser closes. We use session Cookies to help us track internet
              usage as described above.
            </p>
            <p>
              You may refuse to accept browser Cookies by activating the
              appropriate setting on your browser. However, if you select this
              setting you may be unable to access certain parts of the Site.
              Unless you have adjusted your browser setting so that it will
              refuse Cookies, our system will issue Cookies when you direct your
              browser to our Site.
            </p>
            <p>
              The data collected by the Site and/or through Cookies that may be
              placed on your computer will not be kept for longer than is
              necessary to fulfill the purposes mentioned above. In any event,
              such information will not be kept for longer than one year.
            </p>
            <p>Our Cookies are used for the following purposes:</p>

            <Table striped bordered>
              <thead>
                <tr>
                  <th>Cookie Type</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Strictly Necessary/ Technical</td>
                  <td>
                    These Cookies are necessary to allow us to operate our Sites
                    so you may access them as you have requested. These Cookies,
                    for example, let us recognize that you have created an
                    account and have logged into that account to access Site
                    content. They also include Cookies that enable us to
                    remember your previous actions within the same browsing
                    session and secure our Sites.
                  </td>
                </tr>
                <tr>
                  <td>Analytical/ Performance</td>
                  <td>
                    These Cookies are used by us or third party service
                    providers to analyze how the Sites are used and how they are
                    performing. For example, these Cookies track what pages are
                    most frequently visited, and from what locations our
                    visitors come from. If you subscribe to a newsletter or
                    otherwise register with the Sites, these Cookies may be
                    correlated to you. These Cookies include, for example,
                    Google Analytics cookies.
                  </td>
                </tr>
                <tr>
                  <td>Functionality</td>
                  <td>
                    These Cookies let us operate the Sites in accordance with
                    the choices you make. These Cookies permit us to "remember"
                    you in-between visits. For instance, we will recognize your
                    user name and remember how you customized the Sites and
                    services, for example by adjusting text size, fonts,
                    languages and other parts of web pages that are alterable,
                    and provide you with the same customizations during future
                    visits.
                  </td>
                </tr>
                <tr>
                  <td>Third Party Advertising</td>
                  <td>
                    These Cookies collect information about your activities on
                    these and other sites to provide you targeted advertising.
                    We may also allow our third party service providers to use
                    Cookies on the Sites for the same purposes identified above,
                    including collecting information about your online
                    activities over time and across different websites. The
                    third party service providers that generate these Cookies,
                    such as{" "}
                    <a
                      href="https://www.adobe.com/privacy/policy.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Adobe
                    </a>
                    ,
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Google
                    </a>
                    ,{" "}
                    <a
                      href="https://www.linkedin.com/legal/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      LinkedIn
                    </a>
                    ,
                    <a
                      href="https://twitter.com/en/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.facebook.com/policy.php"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                    , have their own privacy policies, and may use their Cookies
                    to target advertising to you on other websites, based on
                    your visit to our Site.
                  </td>
                </tr>
              </tbody>
            </Table>

            <h4 className={"minorTitle mt-2em"}>
              How do I refuse or withdraw my consent to the use of Cookies?
            </h4>
            <p className={"pureText"}>
              If you do not want Cookies to be dropped on your device, you can
              adjust the setting of your Internet browser to reject the setting
              of all or some Cookies and to alert you when a Cookie is placed on
              your device. For further information about how to do so, please
              refer to your browser ‘help’ / ‘tool’ or ‘edit’ section or see{" "}
              <a
                href="https://www.allaboutcookies.org"
                target="_blank"
                rel="noreferrer"
              >
                www.allaboutcookies.org
              </a>
              . Please note that if you use your browser settings to block all
              Cookies (including strictly necessary Cookies) you may not be able
              to access or use all or parts or functionalities of our Sites.
            </p>
            <p className={"pureText"}>
              If you want to remove previously-stored Cookies, you can manually
              delete the Cookies at any time. However, this will not prevent the
              Sites from placing further Cookies on your device unless and until
              you adjust your Internet browser setting as described above.
            </p>
            <p className={"pureText"}>
              For more information on the development of user-profiles and the
              use of targeting/advertising Cookies, please see{" "}
              <a
                href="https://www.youronlinechoices.eu"
                target="_blank"
                rel="noreferrer"
              >
                www.youronlinechoices.eu
              </a>{" "}
              if you are located in Europe or{" "}
              <a
                href="https://www.aboutads.info/choices"
                target="_blank"
                rel="noreferrer"
              >
                www.aboutads.info
              </a>{" "}
              if in the United States.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              How do we use Other Tracking Technologies in our communications?
            </h4>
            <p className={"pureText"}>
              McKinsey may collect information about whether you open or click
              any links in the knowledge, research or event communications that
              we send to you through web beacons.
            </p>
            <p className={"pureText"}>
              A web beacon is a graphic image, which is placed on a website or
              in an email that alone or in conjunction with cookies compile
              information about your usage of or your interaction with a
              McKinsey website or email. For example, we may add web beacons to
              the knowledge, research or event communications that we send to
              you to determine whether you have opened our email or clicked a
              link. The analysis gathered helps us improve the effectiveness of
              content and format of our websites and email communications.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              How do I refuse or withdraw my consent to the use of Web Beacons?
            </h4>
            <p className={"pureText"}>
              You may avoid web beacons by configuring your email reader program
              to disable the functionality that enables remote images to load
              and by refraining from clicking on any links in email messages.
            </p>

            <h4 className={"minorTitle mt-2em"}>Contact us</h4>
            <p className={"pureText"}>
              If you have any other questions about our Cookie Policy, please
              contact us at:
            </p>
            <p className={"pureText"}>
              <span className={"minorTitle"}>e-mail:</span>{" "}
              <a href="mailto:hopelabs@quasiscience.com">
                hopelabs@quasiscience.com
              </a>
            </p>
            {/* <p >post:</p>
                            <p>To be decided,Buckingham,MK18 XXX,UK</p> */}
          </Col>
        </Row>
      </section>
    </Layout>
  );
};

export default About;
